import React from "react";
import logo from "../images/logo_white_small.png";
import "./footer.css";

function Footer(props) {
  return (
    <div className="footer cols">
      <div className="three-col">
        <a
          href="/"
          className="logo-link"
          style={{ position: "relative", left: -3 }}
        >
          <img alt="Ohmi logo" src={logo} className="logo" />
        </a>
        {/* <h4>Ohmi AB</h4> */}
        <p style={{ marginBottom: 0, marginTop: 5 }}>Skeppsbron 36</p>
        <p>111 30, Stockholm</p>
        <p>© 2023 Ohmi AB</p>
      </div>
      <div className="three-col empty">
        {/* <h4>B2B</h4>
        <a className="arrow" href="#">
          Become a partner
        </a>
        <a className="arrow" href="#">
          B2B - FAQ
        </a> */}
      </div>
      <div className="three-col">
        <button
          className="download-cta"
          onClick={() => props.setShowModal(!props.showModal)}
        >
          Download
        </button>
        <p style={{ marginTop: 20 }}>
          <a href="/privacy-policy">Privacy policy</a>
        </p>
        {/* <a className="arrow" href="#">
          Get started
        </a>
        <a className="arrow" href="#">
          Charge map
        </a>
        <a className="arrow" href="#">
          Newsroom
        </a>
        <a className="arrow" href="#">
          FAQ
        </a> */}
      </div>
    </div>
  );
}

export default Footer;
