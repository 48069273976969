import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import apple from "../images/apple.png";
import google from "../images/google.png";
import "./ui-layout.css";
import MainMenu from "../components/main.menu";
import Footer from "../components/footer";

function UILayout(props) {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  useEffect(() => {
    props.setCurrentRoute(
      location.pathname === "/" ? "home" : location.pathname.split("/")[1]
    );
  }, []);
  return (
    <div className="ui-wrapper">
      <MainMenu
        currentRoute={props.currentRoute}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <div className="content-wrapper">{props.children}</div>
      <Footer setShowModal={setShowModal} showModal={showModal} />
      {showModal ? (
        <div
          className={`modal ${showModal ? "show" : ""}`}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <div
            className="inner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2>Download Ohmi</h2>
            <div className="app-btn-wrp">
              <a href="https://apps.apple.com/se/app/ohmi/id6445848475">
                <img
                  alt="Apple download"
                  src={apple}
                  className="app-btn apple"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ohmi">
                <img
                  alt="Google download"
                  src={google}
                  className="app-btn google"
                />
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default UILayout;
