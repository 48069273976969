import React from "react";
import "./terms.css";

function TermsPrivacy(props) {
  return (
    <>
      <div className="terms">
        <div className="section">
          <div className="cols" style={{ justifyContent: "center" }}>
            <div className="one-col" style={{ maxWidth: 680 }}>
              <h1>Integritetspolicy</h1>
              <p>
                Denna integritetspolicy beskriver vilken typ av personlig
                information Ohmi (hädanefter "vi" eller "oss") samlar in från
                våra kunder och användare av våra EV-laddningstjänster, hur vi
                använder denna information och vilka åtgärder vi vidtar för att
                skydda din personliga information. Genom att använda våra
                tjänster godkänner du villkoren i denna integritetspolicy.
              </p>
              <h3>Informationen vi samlar in</h3>
              <p>
                Vi samlar in personlig information som du tillhandahåller när du
                skapar ett konto eller använder våra tjänster, såsom ditt namn,
                e-postadress, betalningsinformation och EV-laddningspreferenser.
                Vi kan också samla in information om din EV-laddningshistorik,
                platsdata och teknisk information om din enhet.
              </p>
              <h3>Användning av informationen</h3>
              <p>
                Vi använder den personliga informationen vi samlar in för att
                tillhandahålla och förbättra våra EV-laddningstjänster, hantera
                ditt konto, behandla betalningar och kontakta dig med
                information om våra tjänster. Vi kan också använda den
                personliga informationen för att uppfylla lagliga skyldigheter,
                såsom att tillhandahålla information till myndigheter.
              </p>
              <h3>Delning av Informationen</h3>
              <p>
                Vi delar inte din personliga information med tredje part för
                marknadsföringsändamål utan ditt samtycke. Vi kan emellertid
                dela din personliga information med våra betrodda partners som
                tillhandahåller tjänster för oss, såsom betalningsbehandlare och
                IT-tjänsteleverantörer. Vi delar också din personliga
                information med vårt samarbetsföretag Virta för att
                tillhandahålla EV-laddningstjänster. Vi säkerställer att vårt
                samarbete med Virta är i enlighet med GDPR.
              </p>
              <h3>Säkerhet</h3>
              <p>
                Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
                skydda din personliga information mot obehörig åtkomst, ändring,
                förstörelse eller förlust. Vi begränsar åtkomsten till din
                personliga information endast till de anställda och
                tjänsteleverantörer som behöver tillgång till den för att utföra
                sina arbetsuppgifter.
              </p>
              <h3>Dina Rättigheter</h3>
              <p>
                Du har rätt att begära tillgång till och rättelse av din
                personliga information som vi har om dig. Du har också rätt att
                begära radering av din personliga information, under
                förutsättning att det inte krävs för att uppfylla våra lagliga
                skyldigheter. Om du har några frågor om vår integritetspolicy
                eller hur vi behandlar din personliga information, kan du
                kontakta oss genom att använda kontaktuppgifterna nedan.
              </p>
              <h3>Kontaktinformation</h3>
              <p>
                E-post: <a href="mailto: info@ohmi.app">info@ohmi.app</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsPrivacy;
