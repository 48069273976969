import React, { useState } from "react";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import hero from "../images/hero.png";
import apple from "../images/apple.png";
import google from "../images/google.png";
import leaf from "../images/leaf.png";
import virta from "../images/virta_yellow.png";
import "./home.css";

function Home(props) {
  const [showHero, setShowHero] = useState(false);
  const [showTextBlock, setShowTextBlock] = useState(false);
  const [showPartners, setShowPartners] = useState(false);
  const [showGreen, setShowGreen] = useState(false);
  return (
    <>
      <div className="home">
        <Fade
          triggerOnce={true}
          onVisibilityChange={() => {
            setShowHero(true);
          }}
          delay={100}
        >
          <div className="hero section" style={{ opacity: showHero ? 1 : 0 }}>
            <h1 style={{ alignSelf: "center" }}>
              This is how you should ride.
            </h1>
            <img alt="Descritive images from the app" src={hero} />
            <div className="app-btn-wrp">
              <a href="https://apps.apple.com/se/app/ohmi/id6445848475">
                <img
                  alt="appstore_download"
                  src={apple}
                  className="app-btn apple"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ohmi">
                <img
                  alt="google_download"
                  src={google}
                  className="app-btn google"
                />
              </a>
            </div>
          </div>
        </Fade>
        <Fade
          triggerOnce={true}
          cascade={true}
          onVisibilityChange={() => {
            setShowTextBlock(true);
          }}
          delay={300}
        >
          <div className="section" style={{ opacity: showTextBlock ? 1 : 0 }}>
            <div className="cols" style={{ marginBottom: 40 }}>
              <div className="two-col">
                <h2 style={{ marginBottom: 10 }}>
                  EV management as it should be.
                </h2>
                <h3 style={{ fontWeight: "300" }}>
                  Multiple apps? No more. Forgot your RFID-tag? That's okay.
                  Worried you won't find somewhere to charge? Chill out. Ohmi is
                  here.
                </h3>
              </div>
              <div className="two-col empty"></div>
            </div>
            <div className="cols">
              <div className="three-col">
                <h4>One app to ride it all</h4>
                <p>
                  With Ohmi you get access to thousands of chargers all across
                  the Nordics and Europe.
                </p>
              </div>
              <div className="three-col">
                <h4>Plan trips with ease</h4>
                <p>
                  Tell us where you need to go and we'll show and navigate you
                  to a charger exactly when you need it.
                </p>
              </div>

              <div className="three-col">
                <h4>Hassle free payment</h4>
                <p>
                  Register your credit card once and you are good to go forever.
                </p>
              </div>
            </div>
            {/* <div className="cols">
            <div className="three-col">
              <h4>We've got supplies</h4>
              <p>
                Anything can happen. And when it does, you can find it in the
                shop. Cables, chargers, you name it.
              </p>
            </div>
            <div className="three-col">
              <h4>Looking for something new?</h4>
              <p>
                Browse through your partners leasing offers to find a smooth
                ride at great prices.
              </p>
            </div>
            <div className="three-col">
              <h4>Parking made accessible</h4>
              <p>
                We've teamed up with the largest parking apps there is in order
                to let you find adjacent parkings anytime.pe.
              </p>
            </div>
          </div> */}
          </div>
        </Fade>
        <Fade
          triggerOnce={true}
          cascade={true}
          onVisibilityChange={() => {
            setShowPartners(true);
          }}
          delay={300}
        >
          <div
            className="section partners"
            style={{ opacity: showPartners ? 1 : 0 }}
          >
            <h2>We've partnered up with the best.</h2>
            <div className="inner">
              <img className="partner" src={virta} alt="Virta logo" />
            </div>
          </div>
        </Fade>
        <Fade
          triggerOnce={true}
          onVisibilityChange={() => {
            setShowGreen(true);
          }}
          // fraction={0.5}
          delay={500}
        >
          <div className="section" style={{ opacity: showGreen ? 1 : 0 }}>
            <div
              className="cols"
              style={{ marginBottom: 40, alignItems: "center" }}
            >
              <div className="two-col">
                <img alt="green leaf" src={leaf} className="leaf" />
              </div>
              <div className="two-col">
                <h2 style={{ marginBottom: 10 }}>
                  Green energy. Nothing else.
                </h2>
                <h3 style={{ fontWeight: "300" }}>
                  Driving an EV is not only fun and games, it also help reduce
                  carbon dioxide emissions. You've heard it before, but what you
                  haven't heard this: By using Ohmi, you can be sure that the
                  energy that goes in to your EV comes from renewable sources.
                  Pretty neat huh?
                </h3>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default Home;
