import React from "react";

import leaf from "../images/leaf.png";
import eric from "../images/eric_rund.png";
import niklas from "../images/niklas.png";
import david from "../images/david.png";
import spiros from "../images/spiros.png";
import virta from "../images/virta_yellow.png";
import ln from "../images/LinkedIn_logo_initials.png.webp";
import "./about-us.css";

function AboutUs(props) {
  return (
    <>
      <div className="about-us">
        <div className="section team">
          <div className="cols" style={{ marginBottom: 70 }}>
            <div className="two-col">
              <h2 style={{ marginBottom: 10 }}>We set out on a mission.</h2>
              <h3 style={{ fontWeight: "300" }}>
                The struggle is real for EV drivers. We're on a mission to
                change that. Who are we? Lets find out! 👇
              </h3>
            </div>
            <div className="two-col empty"></div>
          </div>
          <h3>The Co-founders</h3>
          <div className="cols">
            <div className="four-col">
              <img src={spiros} className="team" />

              <h4>Spiros Mohlin</h4>
              <p className="desc">CEO - Head of Partnerships</p>
              <p>
                Senior sales/business manager and Business developer, with
                primary focus on partnerships.
              </p>
              <a
                href="https://www.linkedin.com/in/spirosm/"
                className="social"
                target="_blank"
              >
                <img alt="Spiros Mohlin Linkedin" src={ln} />
              </a>
            </div>
            <div className="four-col">
              <img src={david} className="team" />
              <h4>David Vesali</h4>
              <p className="desc">CSO - Head of Sales</p>
              <p>
                Experienced Sales Developer with 10 years of experience in high
                level sales environments.
              </p>
              <a
                href="https://www.linkedin.com/in/david-vesali-1407a172/"
                className="social"
                target="_blank"
              >
                <img alt="David Vesali Linkedin" src={ln} />
              </a>
            </div>
            <div className="four-col">
              <img src={niklas} className="team" />
              <h4>Niklas Liljendahl</h4>
              <p className="desc">CTO - Fullstack Developer</p>
              <p>
                Yoda. Master of code. Been building and coding stuff for 12
                years and still counting.
              </p>
              <a
                href="https://www.linkedin.com/in/niklas-liljendahl-71425362/"
                className="social"
                target="_blank"
              >
                <img alt="Niklas Liljendahl Linkedin" src={ln} />
              </a>
            </div>
            <div className="four-col">
              <img src={eric} className="team" />
              <h4>Eric Andrén</h4>
              <p className="desc">CPO - Frontend Developer</p>
              <p>
                Pixel maniac. Usability worshiper. Been juggling UX, coding and
                product dev for over 12 years.
              </p>
              <a
                href="https://www.linkedin.com/in/eric-daniel-andr%C3%A9n/"
                className="social"
                target="_blank"
              >
                <img alt="Eric Andrén Linkedin" src={ln} />
              </a>
            </div>
          </div>
        </div>
        <div className="section partners">
          <h2>We've partnered up with the best.</h2>
          <div className="inner">
            <img className="partner" src={virta} alt="Virta logo" />
          </div>
        </div>
        {/* <div className="section">
          <div
            className="cols"
            style={{ marginBottom: 40, alignItems: "center" }}
          >
            <div className="two-col">
              <img alt="green leaf" src={leaf} className="leaf" />
            </div>
            <div className="two-col">
              <h2 style={{ marginBottom: 10 }}>Green energy. Nothing else.</h2>
              <h3 style={{ fontWeight: "300" }}>
                Driving an EV is not only fun and games, it also help reduce
                carbon dioxide emissions. You've heard it before, but what you
                haven't heard this: By using Ohmi, you can be sure that the
                energy that goes in to your EV comes from renewable sources.
                Pretty neat huh?
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default AboutUs;
